import styled from 'styled-components';

const QuoteWrapper = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 40px;

  h2 {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 32px;
    text-align: left;
    margin-bottom: 10px;
  }
  h4 {
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 10px;
  }
  p {
    font-size: 18px;
    font-weight: 600;
  }

  @media screen and (max-width: 1024px) {
    width: 70%;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 0;
    h2 {
      margin-top: 6rem;
      font-size: 20px;
      font-weight: 500;
    }
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }

  p:first-child {
    font-style: italic;
    font-size: 15px;
  }
`;

export default QuoteWrapper;
