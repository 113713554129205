import styled from 'styled-components';

const VideosContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  column-count: 3;
  justify-content: center; /* Center videos horizontally */
  align-items: center;
  padding: 45px 65px 45px 65px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }

  div {
    width: fit-content;
    padding: 20px;
    height: fit-content;
    background-color: white;
    box-shadow: 0 0 10px 0 lightgray;
    margin-bottom: 10px;
    border-radius: 20px;
    border-color: 2px #03baed;
  }

  @media screen and (max-width: 1024px) {
    iframe {
      width: 400px;
      height: 225px;
    }
  }
  @media screen and (max-width: 650px) {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`;
const VideosContainer2 = styled.section`
  display: flex;
  flex-wrap: wrap;
  column-count: 3;
  justify-content: space-around; /* Center videos horizontally */
  align-items: center;
  padding: 45px 65px 45px 65px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }

  div {
    width: fit-content;
    padding: 20px;
    height: fit-content;
    background-color: white;
    box-shadow: 0 0 10px 0 lightgray;
    margin-bottom: 10px;
    border-radius: 20px;
    border-color: 2px #03baed;
  }

  @media screen and (max-width: 1024px) {
    iframe {
      width: 400px;
      height: 225px;
    }
  }
  @media screen and (max-width: 650px) {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`;

export { VideosContainer, VideosContainer2 };
