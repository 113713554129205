import React from 'react';
import QuoteWrapper from './quote.style';

const Quote = ({ media, mainTitle, subTitle, subtext }) => {
  return (
    <QuoteWrapper>
      <h2>{mainTitle}</h2>
      <h4>{subTitle}</h4>
      <p>{subtext}</p>
    </QuoteWrapper>
  );
};

export default Quote;
