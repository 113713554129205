import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import RobertS from '../../../../common/assets/image/saasModern/Robert S.webp';
import Michael from '../../../../common/assets/image/saasModern/ReviewForBookADemo/Michael.webp';
import Richard from '../../../../common/assets/image/saasModern/ReviewForBookADemo/Richard.webp';
import Pierre from '../../../../common/assets/image/saasModern/ReviewForBookADemo/Pierre-Axel.jpg';
import Blank from '../../../../common/assets/image/saasModern/ReviewForBookADemo/Blank.jpg';
import Bharat from '../../../../common/assets/image/saasModern/ReviewForBookADemo/Bharat.jpeg';
import faStar from '../../../../common/assets/image/saasModern/ReviewForBookADemo/fullStarIcon.svg';
import faStarHalf from '../../../../common/assets/image/saasModern/ReviewForBookADemo/halfStarIcon.svg';

import ReviewsWrapper, { ReviewsBox } from './review.style.js';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar } from '@fortawesome/free-solid-svg-icons';
// import { faStarHalf } from '@fortawesome/free-solid-svg-icons';

const Star = ({ isHalf }) => (
  <div className="star-icon">
    {isHalf ? (
      <img
        src={faStarHalf}
        alt="Half Star"
        style={{ width: '28px', height: '28px' }}
      />
    ) : (
      <img
        src={faStar}
        alt="Full Star"
        style={{ width: '20px', height: '20px' }}
      />
    )}
  </div>
);

const Reviews = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviewData = [
    {
      avatar: Pierre,
      name: 'Pierre-Axel B.',
      stars: 4.5,
      content:
        'It has the very potential to become one of the great one of the market Data management is almost perfect and very simular to Zoominfo Buying intent is interesting , cross finger to see if get some leads from it data for france is good so far .',
    },
    {
      avatar: Bharat,
      name: 'Bharat Parmar',
      stars: 5,
      content:
        'Compared to ZoomInfo the product is more robust and inexpensive. I am still positive the Clodura.AI is going to add more features and add some unique features like automated search options on data available. ZoomInfo does provide lot of information for the prospect but I found Clodura.AI is more ideal for what I was looking for. I would recommend Clodura.AI against any product out there.',
    },
    {
      avatar: Michael,
      name: 'Michael S.',
      stars: 5,
      content:
        "Clodura.AI has revolutionized my contact research and marketing efforts, offering valuable analytics and insights. The platform's accurate and up-to-date contact information has significantly improved my outreach, allowing me to target the right prospects effectively. The user-friendly interface makes navigation seamless. ",
    },
    {
      avatar: Richard,
      name: 'Richard L.',
      stars: 4.5,
      content:
        "Clodura is a solid lead generation and email marketing platform with no bugs and lots of options. I've been using it even as an email marketing campaign and there are lots of options and allows many controls, combining different use cases. The platform is very intuitive and easy to use, even for non-technical users.",
    },
    {
      avatar: Blank,
      name: 'kenrickc77',
      stars: 5,
      content:
        'This is a good database that I now use in addition to ZoomInfo and LinkedIn Sales Navigator since it provides additional companies within my sales territory I can pursue. It also provides ISV information. Thus, I highly recommend!',
    },
    {
      avatar: RobertS,
      name: 'Robert S.',
      stars: 5,
      content:
        'The possibility to find all the businesses and people that work for those businesses with their contact informatio. Not to mention the chrome extension that comes with the app. Finding potential leads and their contact so I can email them and try to sale my web design and development services to their business.',
    },
    {
      avatar: Blank,
      name: 'akshay.kini7',
      stars: 4,
      content:
        "I've been in lead generation for 5+ years and this tool has a great potential to compete with big players like Zoominfo and Apollo. I am looking forward to buy more codes. However, beware the database is not 100% correct (which is also the case with a premium tool like Zoominfo) I am looking forward to see this tool grow and not stop here.",
    },
    // Add more review data objects as needed
  ];

  return (
    <div>
      <Slider {...settings}>
        {reviewData.map((review, index) => (
          <div key={index}>
            <ReviewsWrapper id={`g2-slide-${index}`}>
              <ReviewsBox avatar={review.avatar}>
                <div className="reviews-image"></div>
                <h3>{review.name}</h3>
                <div className="star">
                  {Array.from({ length: Math.floor(review.stars) }, (_, i) => (
                    <Star key={i} />
                  ))}
                  {review.stars % 1 !== 0 && <Star isHalf />}
                </div>
                <p>{review.content}</p>
              </ReviewsBox>
            </ReviewsWrapper>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default Reviews;
