// clientLogos.style.js

import styled from 'styled-components';

const ClientLogosWrapper = styled.section`
  max-width: 90%;
  margin: 20px auto; /* Center horizontally and provide some top margin */
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns, adjust as needed */
  align-items: center; /* Center logos vertically */
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr); /* Adjust for smaller screens */
    gap: 10px;
  }
`;

export const SpecialImage = styled.img`
  width: 100%;
  aspect-ratio: ${(props) => props.propWidth / props.propHeight};
  border-radius: 8px; /* Optional: Add border-radius for rounded corners */
  overflow: hidden; /* Optional: Hide overflowing content */
`;

export default ClientLogosWrapper;
