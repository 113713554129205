import React, { Fragment } from 'react';
import Box from 'common/components/Box';

import { VideosContainer, VideosContainer2 } from './videos.style';
import PropTypes from 'prop-types';

const Videos = ({ title }) => {
  return (
    <Fragment>
      <h2
        style={{
          textAlign: 'center',
          fontSize: '36px',
          padding: '0px 10px',
        }}
      >
        Uncover the{' '}
        <span style={{ fontSize: '52px' }}>Power of Clodura.AI </span>
        in Action
      </h2>
      <h3
        style={{
          textAlign: 'center',
          fontSize: '24px',
          padding: '0px 10px',
          fontWeight: '500',
        }}
      >
        Experience the power of Clodura.AI through a comprehensive demo video.
        Witness the AI-driven insights, automation, and personalization that can
        revolutionize your sales approach.
      </h3>
      <VideosContainer style={{ marginBottom: '-80px' }}>
        <Box>
          <iframe
            width="1200"
            height="500"
            src="https://www.youtube.com/embed/xdkAOOdJikw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
      </VideosContainer>
      <VideosContainer2>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/I2BRW3HYRPE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/wrYMlhfWkm0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/Lp24p8eTOHI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
      </VideosContainer2>
    </Fragment>
  );
};

Videos.propTypes = {
  title: PropTypes.object,
};

Videos.defaultProps = {
  title: {
    fontSize: ['24px', '30px', '30px', '40px', '40px'],
    fontWeight: '600',
    textAlign: 'center',
  },
};

export default Videos;
