import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Quote from '../Quote/index.js';
import { InlineWidget } from 'react-calendly';
import Button from 'common/components/Button';
import LeftSideSvg from '../../../../common/assets/image/saasModern/ReviewForBookADemo/left-side.svg';
import RightSideSvg from '../../../../common/assets/image/saasModern/ReviewForBookADemo/right-side.svg';
import ContainerSectionWrapper, {
  InputGroup,
} from './containerSection.style.js';

const ContainerSection = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [showInlineWidget, setShowInlineWidget] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const DemoPageData = useStaticQuery(graphql`
    {
      allStrapiDemoPageHeader {
        nodes {
          mainTitle
          subTitle
          media {
            localFile {
              publicURL
            }
          }
          subtext
        }
      }
    }
  `);

  const quoteData = DemoPageData.allStrapiDemoPageHeader.nodes[0];

  useEffect(() => {
    const storedName = localStorage.getItem('name');
    const storedEmail = localStorage.getItem('email');
    setFirstName(storedName || ''); // Use setFirstName instead of setName
    setEmail(storedEmail || '');
  }, []);

  useEffect(() => {
    // Extract utm_source from URL query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('utm_source');
    setUtmSource(source || ''); // Set the utm_source state
  }, []);

  const valid =
    email &&
    email.length > 0 &&
    firstName &&
    firstName.length > 0 &&
    lastName &&
    lastName.length > 0; // Update this line

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailDomain = email.split('@')[1].toLowerCase();
    try {
      const response = await fetch(
        `https://m.api.azr.clodura.com/freemailpros?msp_eq=${emailDomain}`,
        {
          method: 'GET',
          mode: 'cors',
          credentials: 'same-origin',
        }
      );
      if (response.ok) {
        const res = await response.json();
        if (res.length === 0) {
          setShowInlineWidget(true); // Show Calendly widget immediately
          setIsValidEmail(true);
          localStorage.setItem('name', firstName);
          localStorage.setItem('email', email.toLowerCase());
          const body = {
            data: {
              firstName,
              lastName,
              email: email.toLowerCase(),
              utmSource,
            },
          };
          try {
            const resourceResponse = await fetch(
              'https://m.api.corp.azr.clodura.ai/api/request-book-a-demos/',
              {
                method: 'POST',
                mode: 'cors',
                credentials: 'same-origin',
                headers: {
                  Authorization: `bearer ${process.env.STRAPI_TOKEN}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
              }
            );
            if (!resourceResponse.ok) {
              alert('Oops! Something went wrong');
            }
          } catch (error) {
            // alert('Something went wrong2');
          }
        } else {
          setIsValidEmail(false);
        }
      }
    } catch (error) {
      alert('Something went wrong');
    }
  };

  return (
    <div>
      <ContainerSectionWrapper style={{ padding: '0' }}>
        <div className='heading-container'>
          <div className='triangle-left'>
            <img src={LeftSideSvg} alt='Left Triangle' />
          </div>
          <h1 className='heading-tag'>
            <span className='small-span'>Book a Demo </span>
            and Revolutionize Your Sales
            <span className='small-span'> Now</span>
          </h1>
          <div className='triangle-right'>
            <img src={RightSideSvg} alt='Right Triangle' />
          </div>
        </div>
      </ContainerSectionWrapper>

      <ContainerSectionWrapper>
        <Quote {...quoteData} />
        {!showInlineWidget ? (
          <form className='fm' onSubmit={handleSubmit}>
            <InputGroup>
              <label htmlFor='fname'>
                First Name<span>*</span>
              </label>
              <input
                className='download-input'
                type='text'
                id='fname'
                name='firstName'
                placeholder='Enter Your First Name'
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                required
              />
            </InputGroup>
            <InputGroup>
              <label htmlFor='lname'>
                Last Name<span>*</span>
              </label>
              <input
                className='download-input'
                type='text'
                id='lname'
                name='lastName'
                placeholder='Enter Your Last Name'
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required
              />
            </InputGroup>
            <InputGroup>
              <label htmlFor='email'>
                Work Email<span>*</span>
              </label>
              <input
                className={`download-input ${
                  !isValidEmail ? 'invalid-email' : ''
                }`}
                type='email'
                id='email'
                name='workemail'
                placeholder='Enter Your Work Email'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
              {!isValidEmail && (
                <span className='invalid-email'>
                  Please enter your corporate email address only to book
                  <br />a demo!
                </span>
              )}
            </InputGroup>
            <InputGroup>
              <Button
                className='book-demo-btn'
                title='Book A Demo'
                type='submit'
                disabled={!valid}
                style={{
                  cursor: valid ? 'pointer' : 'not-allowed',
                }}
              />
            </InputGroup>
          </form>
        ) : (
          <InlineWidget
            url={`https://calendly.com/cloduraai/bookademo?hide_event_type_details=1&hide_gdpr_banner=1`}
            prefill={{
              firstName: firstName,
              lastName: lastName,
              email: email,
            }}
          />
        )}
      </ContainerSectionWrapper>
      <ContainerSectionWrapper style={{ marginTop: '20px' }}>
        <div className='link-container'>
          <Link
            href='https://www.g2.com/products/clodura-ai/reviews?utm_source=review-widget'
            title='Read reviews of Clodura.AI on G2'
          >
            <img
              className='full-width'
              style={{
                maxWidth: '200px',
                alignContent: 'center',
                marginTop: '20px',
                marginBottom: '-20px',
              }}
              alt='Read Clodura.AI reviews on G2'
              src='https://www.g2.com/products/clodura-ai/widgets/stars?color=white&amp;type=read'
            />
          </Link>
        </div>
      </ContainerSectionWrapper>
    </div>
  );
};

export default ContainerSection;
