import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ClientLogosWrapper, { SpecialImage } from './clientLogos.style';

const ClientLogos = () => {
  const CustomersData = useStaticQuery(graphql`
    {
      allStrapiCustomer {
        nodes {
          logos {
            height
            width
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const logos = CustomersData.allStrapiCustomer.nodes[0].logos;

  // Display only 10 logos
  const displayedLogos = logos.slice(0, 10);

  const logosPerRow = 5; // Adjust this based on your design
  const upperRowLogos = displayedLogos.slice(0, logosPerRow);
  const lowerRowLogos = displayedLogos.slice(logosPerRow);

  return (
    <>
      <h2
        style={{
          textAlign: 'center',
          fontSize: '36px',
          padding: '0px 10px',
          marginTop: '-20px',
        }}
      >
        Driving growth for
        <span style={{ fontSize: '52px' }}> 12000+ </span>happy customers.
      </h2>
      <ClientLogosWrapper>
        {upperRowLogos.map((logo, index) => (
          <SpecialImage
            key={index}
            src={logo.localFile.publicURL}
            alt="Logo"
            propHeight={logo.height}
            propWidth={logo.width}
            style={{ gridRow: '1' }} // Place logos in the first row
          />
        ))}
        {lowerRowLogos.map((logo, index) => (
          <SpecialImage
            key={index}
            src={logo.localFile.publicURL}
            alt="Logo"
            propHeight={logo.height}
            propWidth={logo.width}
            style={{ gridRow: '2' }} // Place logos in the second row
          />
        ))}
      </ClientLogosWrapper>
    </>
  );
};

export default ClientLogos;
