import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';

import Navbar from 'containers/SaasModern/Navbar';
import BannerSection from 'containers/Custom/Common/Banner';
import ContainerSection from 'containers/Custom/Demo/ContainerSection';
import Footer from 'containers/AgencyModern/Footer';
import Seo from 'components/seo';
import { Helmet } from 'react-helmet';
import G2Slider from '../containers/SaasModern/G2Badges';
import Review from '../containers/Custom/Demo/Review';
import Videos from '../containers/Custom/Demo/ReviewVideo';
import ClientLogos from '../containers/Custom/Demo/ClientLogos';
import Thanksgiving from '../containers/SaasModern/Thanksgiving';
import BookDemoButton from '../containers/SaasModern/ScheduleAdemo/ButtonDemo';

const Demo = () => {
  const DemoPageData = useStaticQuery(graphql`
    {
      allStrapiDemoMainBanner {
        nodes {
          part {
            title
            description
            media {
              internal {
                content
              }
            }
          }
        }
      }
    }
  `);

  const mainBannerData = DemoPageData.allStrapiDemoMainBanner.nodes[0].part;

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title='Book a Demo with Clodura.AI and Transform Your Sales Today'
          description='Want the best online lead gen software for your organisation? Get in touch with us at Clodura.AI for best B2B Sales data. Request Demo.'
          robots='index, follow'
          canonical='https://www.clodura.ai/request-demo/'
        />
        <Helmet>
          <meta
            property='og:image'
            content='https://storage.googleapis.com/app_assets_bucket_002/website/Book-request-a-demo.png'
          />
          <script
            type='text/javascript'
            src='https://assets.calendly.com/assets/external/widget.js'
            async
          ></script>
        </Helmet>
        <ResetCSS />

        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>
          <ContainerSection />
          <G2Slider />
          <ClientLogos />
          <Review />
          <Videos />
          <Footer />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default Demo;
