import styled from 'styled-components';

const ReviewsWrapper = styled.section`
  display: flex;
  justify-content: space-between; /* Use space-between to evenly space the boxes */
  flex-wrap: wrap; /* Allow boxes to wrap to the next line on smaller screens */
  margin: 40px 30px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ReviewsBox = styled.div`
  margin-top: 20px;
  min-height: 480px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5%;
  margin-bottom: 20px;
  background-color: white;

  .star {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #03baed;
    margin-bottom: 3px;
  }

  .feature-size {
    text-align: center;
    font-size: 30px;
    background-color: whitesmoke;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }

  > .reviews-image {
    margin: -70px auto 20px; /* Adjust margin to control the positioning */
    height: 120px;
    width: 120px;
    border-radius: 50% 50%;
    background: url(${(props) => (props.avatar ? props.avatar : null)});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    border: 4px solid #05bbed;
  }

  > h4 {
    min-height: 15px;
    @media screen and (max-width: 1024px) {
      min-height: 80px;
    }
    @media screen and (max-width: 768px) {
      min-height: 100px;
    }
    @media screen and (max-width: 600px) {
      min-height: unset;
    }
  }

  > p {
    color: #a3a3a3;
    font-size: 14px;

    min-height: 50px;

    @media screen and (max-width: 1024px) {
      min-height: 220px;
    }
    @media screen and (max-width: 768px) {
      min-height: 300px;
    }
    @media screen and (max-width: 600px) {
      min-height: unset;
    }
  }
`;

export default ReviewsWrapper;
